 .pac-container {
   box-shadow: 0 1px 6px rgb(0 0 0 / 10%);
   border-radius: 5px;
   border: 0;
   padding: 0 8px;
 }

 .pac-container:after {
   background-image: none !important;
   height: 0;
 }

 .pac-icon {
   display: none;
 }

 .pac-item {
   padding: 12px 16px 12px 40px;
   margin: 3px -8px;
   border: none;
   background: #f4fbfe
 }

 .pac-item,
 .pac-matched,
 .pac-item-query {
   font-family: system-ui, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
   font-weight: 500;
   font-size: 16px !important;
   line-height: 1.45;
   color: rgba(0, 0, 0, .87);
   cursor: pointer;
 }

 .pac-matched {
   background: none;
 }

 .pac-item:hover {
   background: #fff4cc;
 }

 .pac-item-selected {
   background: #fff2de;
 }