html {
  font-size: 16px;
  @media (max-width: 315px) {
    font-size: 10px !important;
  }
  @media (max-width: 370px) {
    font-size: 13px;
  }
  @media (min-width: 1920px) {
    font-size: 24px;
  }
}

html, body, #root {
  height: 100%;
}

.SnackbarItem-message {
  white-space: pre-wrap;
  .MuiSvgIcon-root {
    margin-inline-end: 1rem !important;
  }
}

.SnackbarContent-root {
  flex-wrap: nowrap !important;
}

.SnackbarContainer-root {
  @media (max-width: 480px) {
    bottom: 5rem !important;
  }
}
